<template>
  <section class="page__block join-club">
    <div class="container">
      <div class="join-club__block">
        <div class="join-club__main">
          <div class="join-club__content">
            <h3 class="join-club__title">{{ $t("joinClub.title") }}</h3>
            <p class="join-club__text">
              Онлайн дисконт-пространство напитков и интересных тематических предложений со скидками
            </p>
          </div>
          <div class="join-club__button">
            <a
              v-if="!$cookies.get('profile')"
              class="join-club__btn"
              @click="openIsAuthActive"
              >{{ $t("joinClub.button") }}</a
            >
            <a
              class="join-club__btn"
              @click.self="isOpen = !isOpen"
              v-else
              >Вы уже зарегистрированы</a
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal"
      @click.self="isOpen = !isOpen"
      v-if="isOpen"
    >
      <!-- <div class="modal__container"> -->
      <div class="already">
        <h2 class="already__title">Вы уже зарегистрированы</h2>
        <button
          class="already__button"
          @click.prevent="isOpen = false"
        >
          Закрыть
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex"
import { actionTypes } from "@/store"
export default {
  name: "AppJoinClub",
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    ...mapActions({
      openIsAuthActive: actionTypes.changeIsAuthActive,
    }),
  },
}
</script>

<style
  lang="scss"
  scoped
>
.already {
  background: #fff;
  padding-bottom: 40px;
  max-width: 500px;
  width: 100%;
  height: 200px;
  // display: inline-flex;
  text-align: center;
  border-radius: 20px;
  margin: auto;
  &__title {
    padding: 50px 0 0;
    font-size: 24px;
    margin-bottom: 40px;
    // height: 80%;
    font-family: "CeraPro Bold";
  }
  &__button {
    background-color: #8344d6;
    color: #ffffff;
    text-align: center;
    width: 100%;
    max-width: 200px;
    padding: 14px 0;
    border-radius: 10px;
    // align-self: flex-end  ;
    // margin-top: auto;
    // margin-bottom: 56px;
    // cursor: not-allowed;
  }
}
</style>
